import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import jesonLebron from "../assets/img/selim-goksin.jpg"
import team1 from "../assets/img/main/trafik-edinme.jpg"
import googleAds from "../assets/img/google-img.png"
import linkedinAds from "../assets/img/linkedin-ads.png"
import fbIns from "../assets/img/fb-instagram.png"
import acquire2 from "../assets/img/teams/edinme-seo.jpg"
import acquire3 from "../assets/img/teams/edinme-sm.jpg"
import acquire4 from "../assets/img/teams/edinme-mail.jpg"
import checkModal from "../assets/img/check-modal.png";


class Convert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
      value: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    e.preventDefault();

    this.setState({ value: e.target.value });
  }
  showModal(e) {

    this.setState({ display: !this.state.display });
    document.querySelector('.check-modal').classList.add('active-modal');

    document.querySelector('#site').innerHTML = this.state.value;
  }

  closeModal() {
    this.setState({ display: !this.state.display })
    document.querySelector('.check-modal').classList.remove('active-modal');
  }
  handleSubmit(e) {

    e.preventDefault();
    const title = document.title.split('|', 1).toString();
    const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
      method: 'POST',
      body: JSON.stringify({
        "text_327649": title,
        "text_204202": document.querySelector('#site').value,
        "text_775908": document.querySelector('#name').value,
        "text_532192": document.querySelector('#email').value,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    });
    response.then(res => {
      if (res.status === 200) {
        document.querySelector('.status').innerHTML = "Success!";
      }
      else {
        document.querySelector('.status').innerHTML = "Error";
      }
    });
  }
  render() {
    return (
      <Layout>
        <SEO title="Acquisition" description="We are launching digital campaigns that will bring your potential customers to your website." />
        <div className="acquire">
          <div className="convert-hero container">
            <div className="col-md-4 noPadding">
              <div className="top">
                <h1>Acquisition</h1>
                <p>We are launching digital campaigns that will bring your potential customers to your website.</p>
                <p className="content-link" onClick={(e) => this.showModal()}>Get free brand analysis.</p>
              </div>
              <div className="bottom">
                <h2>eçanta</h2>
                <p>“Thanks to Flatart's strategies and correct, down to earth, what was left to us was to be patient and see that our targets have been reached in the end.” </p>
                <div className="profile">
                  <div> <img alt="alt text" src={jesonLebron}></img></div>
                  <div><p>Selim Goksin</p>
                    <p>Co-Founder</p></div>
                </div>
              </div>
            </div>
            <div className="col-md-8 convert-image">
              <img alt="alt text" src={team1} />
              <div className="right-content">
                <div className="col-md-4">
                  <h3>High-Quality Traffic</h3>
                  <p>We bring the audience that has a high probability of interest rate to your brand.</p>
                </div>
                <div className="col-md-4">
                  <h3>Media Outlets Suitable for You</h3>
                  <p>We chose the best ones among a variety of traffic channels that will enable you to reach your target audience in the most optimized way.</p>
                </div>
                <div className="col-md-4">
                  <h3>Transparency</h3>
                  <p>We are completely transparent! We share with you the results of all the work we do to get quality traffic.</p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="convert-traffic py60">
          <h2>Actions to ensure your  <br />
          website gets high-quality traffic.</h2>
          <p><span>SERVICES <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 563 1061.4" space="preserve">
            <polygon points="123.1,0 0,100.7 356.3,536.2 0.3,958.9 121.9,1061.4 563,537.6 " />
          </svg></span> Acquisition</p>
        </div>

        <div className="acquire-bottom py60">
          <div className="acquire-bottom__left">
            <div className="acquire-bottom__left-img">
              <img alt="alt text" src={acquire2} />
            </div>
            <div className="acquire-bottom__left-content">
              <h2>Search Engine <br />Optimization (SEO)</h2>
              <p>We take actions that will bring your brand forward into the top search engine results. This will speed up the transformation by preparing the ground for your target audience to easily reach the products and services you offer.</p>
              <p className="small-content">
                - High conversion rates<br />
                - Increasing competitiveness<br />
                - Improved user experience
          </p>
              <p style={{ marginBottom: 0 }}><Link to="/search-engine-optimization/" className="content-link">Check out the SEO process.</Link></p>
            </div>
          </div>

          <div className="acquire-bottom__right">
            <div className="acquire-bottom__left-content">
              <h2>Social Media Management</h2>
              <p>We create content that adds value to your brand and increases the traffic on your website. This will help you to reach your target audience by marketing in the right channels. Your brand will benefit immensely from the right social media management.</p>
              <p className="small-content">
                - Increasing organic traffic<br />
                - Providing digital visibility<br />
                - Customer loyalty
              </p>
              <p style={{ marginBottom: 0 }}><Link to="/social-media/" className="content-link">Check out the social media process.</Link></p>
            </div>
            <div className="acquire-bottom__right-img"><img alt="alt text" src={acquire3} /></div>
          </div>

          <div className="acquire-bottom__left">
            <div className="acquire-bottom__left-img"><img alt="alt text" src={acquire4} /></div>
            <div className="acquire-bottom__left-content">
              <h2>E-mail Marketing</h2>
              <p>We regularly prepare engaging e-mail newsletters to increase traffic rates and enable you to speed up your sales cycle.</p>
              <p className="small-content">
                - Affordable cost<br />
                - High clicking rates<br />
                - Increased brand awareness
          </p>
              <p style={{ marginBottom: 0 }}><Link to="/email-marketing/" className="content-link">Check out the email marketing process.</Link></p>
            </div>
          </div>
        </div>

        <div className="services-list__block acquire-contents container">
          <div className="convert-service">
            <div className="col-md-5">
              <h2>Google Ads</h2>
              <p>Our team of expert team certified as Google Partner will work to scale your Google Ads advertising campaigns and increase your profits.</p>
              <p className="small-content">
                - Increasing brand awareness<br />
                - Getting new customers<br />
                - Cost control
             </p>
              <p> <Link to="/digital-ads/" className="content-link">Check out the Google Ads process.</Link></p>
            </div>
            <div className="col-md-5">
              <img alt="alt text" className="acquire-google-img" src={googleAds}></img>
            </div>
          </div>

          <div className="convert-service" id="facebook-reklamlari">
            <div className="col-md-5 fb-inst" style={{ paddingRight: 150 }}>
              <img alt="alt text" src={fbIns}></img>
            </div>
            <div className="col-md-5 fb-inst-c" style={{ paddingLeft: 70 }} >
              <h2>Facebook<br /> and Instagram Ads</h2>
              <p>We strategize and implement Facebook and Instagram ad campaigns that will reach exactly the right audience and provide high conversion rates. We manage the whole advertising procedure for effective results.</p>
              <p className="small-content">
                - Increasing brand visibility<br />
                - Target the most relevant audience<br />
                - Real-time results
        </p>
            </div>
          </div>

          <div className="convert-service linkedin" id="linkedin-reklamlari">
            <div className="col-md-5">
              <h2>LinkedIn Ads</h2>
              <p>We design digital ads for your brand to interact with the professional target audience in an environment where the heart of the business world beats.</p>
              <p className="small-content">
                - Access to the professional audience<br />
                - High ROI potential
        </p>
            </div>
            <div className="col-md-5 linkedin-img" style={{ padding: '0px 95px 0px 35px' }}>
              <img alt="alt text" src={linkedinAds} ></img>
            </div>
          </div>
        </div>


        <div className="check-modal">
          <span onClick={(e) => this.closeModal()}>X</span>
          <h2 className="form-head">Fill the form</h2>             <p className="form-desc">Enter your information in the fields to get information, and we will get back to you as soon as possible.</p>
          <div className="check-modal_container">
            <form onSubmit={this.handleSubmit} >
              <input type="text" value={this.state.value} onChange={this.handleChange} autoFocus={true} name="text_204202" id="site" required  placeholder="Enter your website." />
              <input type="text" id="name" required name="text_775908" placeholder="Enter your name" />
              <input type="email" id="email" required  name="text_532192" placeholder="Enter yor email" />
              <button>SEND</button>
              <div className="status"></div>
            </form>
            <img alt="alt text" src={checkModal} />
          </div>
        </div>
      </Layout>
    )
  }
}
export default Convert
